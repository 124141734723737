import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import IPublication from '../models/IPublication';

const PublicationItem: React.FC<{ publication: IPublication }> = ({
  publication,
}) => {
  return (
    <Card className={'mb-1 mt-1'}>
      <Card.Body>
        <Card.Title>{publication.title}</Card.Title>
        <Card.Text>
          {publication.authors}, {publication.year}
        </Card.Text>
        {publication.link !== '' ?
          <Button variant='primary' href={publication.link}>
            Read article
          </Button> : null
        }
      </Card.Body>
    </Card>
  );
};

export default PublicationItem;
