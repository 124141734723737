import React from 'react';

interface IProps {
  src: string;
  sub: string;
}

const VideoSub: React.FC<IProps> = ({ src, sub }) => {
  let arr = sub.split(" ");
  const bold = arr.slice(0,2).join(" ");
  const title = arr.slice(2).join(" ");
  return (
    <>
      <video width={'100%'} src={src} controls={true} className='mb-1'></video>
      <p><b>{bold}</b> {title}</p>
    </>
  );
};

export default VideoSub;
