import React from 'react';
import PublicationItem from '../components/PublicationItem';
import IPublication from '../models/IPublication';

const publications: IPublication[] = [
  {
    authors: 'Stolarz M, Dziubińska H',
    year: 2017,
    title:
      'Osmotic and salt stresses modulate spontaneous and glutamate-induced action potentials and distinguish between growth and circumnutation in Helianthus annuus seedlings. Frontiers in Plant Science 8:1766',
    link: 'https://www.frontiersin.org/articles/10.3389/fpls.2017.01766/full',
  },
  {
    authors: 'Stolarz M, Dziubińska H',
    year: 2017,
    title:
      'Spontaneous action potentials and circumnutation in Helianthus annuus. Acta Physiologiae Plantarum 39:234',
    link: 'https://link.springer.com/article/10.1007/s11738-017-2528-0',
  },
  {
    authors: 'Stolarz, M, Król E, Dziubińska H',
    year: 2015,
    title:
      'Lithium distinguishes between growth and circumnutation and augments glutamate-induced excitation of Helianthus annuus seedlings. Acta Physiologiae Plantarum  37 (4):69',
    link: 'https://link.springer.com/article/10.1007/s11738-015-1814-y',
  },
  {
    authors: 'Stolarz M, Żuk M, Król E, Dziubińska H',
    year: 2014,
    title:
      'Circumnutation Tracker: novel software for investigation of circumnutation. Plant Methods 10:24 doi:10.1186/1746-4811-10-24',
    link:
      'https://plantmethods.biomedcentral.com/articles/10.1186/1746-4811-10-24',
  },
  {
    authors: 'Stolarz M, Król E, Dziubińska H',
    year: 2010,
    title:
      'Glutamatergic elements in an excitability and circumnutation mechanism. Plant Signaling & Behavior 5:1108-1111',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3115078/',
  },
  {
    authors: 'Stolarz M, Król E, Dziubińska H, Kurenda A',
    year: 2010,
    title:
      'Glutamate induces series of action potentials and a decrease in circumnutation rate in Helianthus annuus. Physiologia Plantarum 138: 329-338',
    link:
      'https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1399-3054.2009.01330.x',
  },
  {
    authors: 'Stolarz M, Król E, Dziubińska H, Zawadzki T',
    year: 2008,
    title:
      'Complex relationship between growth and circumnutations in Helianthus annuus stem. Plant Signaling & Behavior  3: 376-380',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2634306/',
  },
  {
    authors: 'Stolarz M',
    year: 2009,
    title:
      'Circumnutation as a visible plant action and reaction: physiological, cellular and molecular basis for circumnutations. Plant Signaling & Behavior 4: 380-387',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2676747/',
  },
  {
    authors: 'Kurenda A, Stolarz M, Zdunek A',
    year: 2014,
    title:
      'Electrical potential oscillations - movement relations in circumnutating sunflower stem and effect of ion channel and proton pump inhibitors on circumnutation. Physiologia Plantarum doi: 10.1111/ppl.12277',
    link: 'https://onlinelibrary.wiley.com/doi/abs/10.1111/ppl.12277',
  },
  {
    authors: 'Król E, Dziubińska H, Stolarz M, Trębacz K',
    year: 2006,
    title:
      'Effects of ion channel inhibitors on cold- and electrically-induced action potentials in Dionaea muscipula. Biologia Plantarum 50: 411-416',
    link: 'https://link.springer.com/article/10.1007/s10535-006-0058-5',
  },
  {
    authors: 'Król E, Dziubińska H, Trębacz K, Koselski M, Stolarz M',
    year: 2007,
    title:
      'The influence of glutamic and aminoacetic acids on the excitability of the liverwort Conocephalum conicum. Journal of Plant Physiology 164: 773-784',
    link:
      'https://www.sciencedirect.com/science/article/pii/S0176161706001556?via%3Dihub',
  },
  {
    authors: 'Król E, Płachno BJ, Adamec L, Stolarz M, Dziubińska H, Trębacz K',
    year: 2012,
    title:
      "Quite a few reasons for calling carnivores 'the most wonderful plants in the world'. Annals of Botany 109: 47-64",
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3241575/',
  },
  {
    authors: 'Stolarz M, Dziubińska H, Krupa M, Buda A, Trębacz K, Zawadzki T',
    year: 2003,
    title:
      'Disturbances of stem circumnutations evoked by wound-induced variation potentials in Helianthus annuus L. Cellular & Molecular Biology Letters 8: 31-40',
    link: 'http://www.cmbl.org.pl/pdf/Vol8_p031.pdf',
  },
  {
    authors: 'Charzewska A, Kosek W, Zawadzki T',
    year: 2010,
    title:
      'Does circumnutation follow tidal plumb line variations? Biological Rhythm Research 41: 449-455',
    link: 'https://www.tandfonline.com/doi/abs/10.1080/09291010903411468',
  },
  {
    authors: 'Charzewska A',
    year: 2006,
    title:
      'The rhythms of circumnutation in higher plants. Floriculture, Ornamental and Plant Biotechnology, Volume 1 pp 268-275, Global Science Books, UK',
    link:
      'https://www.researchgate.net/publication/268221346_The_rhythms_of_circumnutation_in_higher_plants',
  },
  {
    authors: 'Charzewska A, Zawadzki T',
    year: 2006,
    title:
      'Circadian modulation of circumnutation length, period, and shape in Helianthus annuus. Journal of Plant Growth Regulation 25: 324-331',
    link: 'https://link.springer.com/article/10.1007/s00344-006-0042-5',
  },
  {
    authors: 'Buda A, Zawadzki T, Krupa M, Stolarz M, Okulski W',
    year: 2003,
    title:
      'Daily and infradian rhythms of circumnutation intensity in Helianthus annuus. Physiologia Plantarum 119: 582-589 11',
    link:
      'https://onlinelibrary.wiley.com/doi/abs/10.1046/j.1399-3054.2003.00198.x',
  },
];

const Literature = () => {
  return (
    <>
      {publications.map((pub, index) => (
        <PublicationItem key={index} publication={pub} />
      ))}
    </>
  );
};

export default Literature;
