import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';
import Image from 'react-bootstrap/esm/Image';
import PublicationItem from '../../components/PublicationItem';
import IPublication from '../../models/IPublication';

const publications: IPublication[] = [
  {
    authors: 'Kurenda A, Stolarz M, Zdunek A',
    year: 2014,
    title:
      'Electrical potential oscillations - movement relations in circumnutating sunflower stem and effect of ion channel and proton pump inhibitors on circumnutation. Physiologia Plantarum doi: 10.1111/ppl.12277',
    link: 'http://onlinelibrary.wiley.com/doi/10.1111/ppl.12277/abstract',
  },
];

const Ultradian = () => {
  const { t } = useTranslation('sunflower');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>{t('ultradian.title')}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub
            src='/assets/video/Film 27.webm'
            sub={t('ultradian.movie')}
          />
        </Col>
        <Col xs={12} md={6}>
          <Image fluid src='/assets/img/ultradian.jpg' />
          <p>{t('ultradian.text')}</p>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          {publications.map((pub, index) => (
            <PublicationItem key={index} publication={pub} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Ultradian;
