import React, { Suspense } from 'react';
import Navigation from './components/Navigation';
import { Route, Routes } from 'react-router-dom';
import Home from './sites/Home';
import Literature from './sites/Literature';
import Footer from './components/Footer';
import Loading from './components/Loading';
import Mimosa from './sites/plants/Mimosa';
import Desmodium from './sites/plants/Desmodium';
import Phaseolus from './sites/plants/Phaseolus';
import Medicago from './sites/plants/Medicago';
import Lupinus from './sites/plants/Lupinus';
import Arabidopsis from './sites/plants/Arabidopsis';
import Tinantia from './sites/plants/Tinantia';
import Ipomea from './sites/plants/Ipomea';
import Growth from './sites/sunflower/Growth';
import Electrical from './sites/sunflower/Electrical';
import Ultradian from './sites/sunflower/Ultradian';
import Daily from './sites/sunflower/Daily';
import Light from './sites/sunflower/Light';
import Thermal from './sites/sunflower/Thermal';
import Movements from './sites/sunflower/Movements';
import Ct from './sites/Ct';
import Container from 'react-bootstrap/esm/Container';
import NotFound from './sites/NotFound';
import Sap from './sites/Sap';

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Navigation />
      <Container fluid className={'wrapper p-0'}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='sunflower'>
            <Route path='growth' element={<Growth />} />
            <Route path='electrical' element={<Electrical />} />
            <Route path='ultradian' element={<Ultradian />} />
            <Route path='daily' element={<Daily />} />
            <Route path='light' element={<Light />} />
            <Route path='thermal' element={<Thermal />} />
            <Route path='movements' element={<Movements />} />
          </Route>
          <Route path='plants'>
            <Route path='mimosa' element={<Mimosa />} />
            <Route path='desmodium' element={<Desmodium />} />
            <Route path='phaseolus' element={<Phaseolus />} />
            <Route path='medicago' element={<Medicago />} />
            <Route path='lupinus' element={<Lupinus />} />
            <Route path='arabidopsis' element={<Arabidopsis />} />
            <Route path='tinantia' element={<Tinantia />} />
            <Route path='ipomea' element={<Ipomea />} />
          </Route>
          <Route path='ct' element={<Ct />} />
          <Route path='sap-tracker' element={<Sap />} />
          <Route path='literature' element={<Literature />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Container>
      <Footer />
    </Suspense>
  );
};

export default App;
