import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';
import Figure from 'react-bootstrap/Figure';
import PublicationItem from '../../components/PublicationItem';
import IPublication from '../../models/IPublication';

const publications: IPublication[] = [
  {
    authors: 'Stolarz M, Król E, Dziubińska H, Zawadzki T',
    year: 2008,
    title:
      'Complex relationship between growth and circumnutations in Helianthus annuus stem. Plant Signaling & Behavior  3: 376-380',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2634306/',
  },
  {
    authors: 'Charzewska A, Zawadzki T',
    year: 2006,
    title:
      'Circadian modulation of circumnutation length, period, and shape in Helianthus annuus. Journal of Plant Growth Regulation 25: 324-331',
    link: 'https://link.springer.com/article/10.1007/s00344-006-0042-5',
  },
  {
    authors: 'Buda A, Zawadzki T, Krupa M, Stolarz M, Okulski W',
    year: 2003,
    title:
      'Daily and infradian rhythms of circumnutation intensity in Helianthus annuus. Physiologia Plantarum 119: 582-589 11',
    link:
      'https://onlinelibrary.wiley.com/doi/abs/10.1046/j.1399-3054.2003.00198.x',
  },
];

const Daily = () => {
  const { t } = useTranslation('sunflower');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>{t('daily.title')}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <p>{t('daily.text')}</p>
          <VideoSub src='/assets/video/Film 28.mp4' sub={t('daily.movie')} />
        </Col>
        <Col xs={12} md={6}>
          <Figure>
            <Figure.Image alt='daily' src='/assets/img/daily.jpg' />
            <Figure.Caption>{t('daily.figure')}</Figure.Caption>
          </Figure>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          {publications.map((pub, index) => (
            <PublicationItem key={index} publication={pub} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Daily;
