import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';
import Figure from 'react-bootstrap/Figure';

const Tinantia = () => {
  const { t } = useTranslation('plants');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Tinantia anomala</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{t('tinantia.text.0')} <i>{t('tinantia.text.1')}</i> {t('tinantia.text.2')}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub
            src='/assets/video/Film 23.webm'
            sub={t('tinantia.movie.0')}
          />
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <Figure>
                <Figure.Image alt='tinantia1' src='/assets/img/tinantia1.jpg' />
                <Figure.Caption>{t('tinantia.figure.0')}</Figure.Caption>
              </Figure>
            </Col>
            <Col xs='auto'>
              <Figure>
                <Figure.Image alt='tinantia2' src='/assets/img/tinantia2.jpg' />
                <Figure.Caption>{t('tinantia.figure.1')}</Figure.Caption>
              </Figure>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xl={{ span: 10, offset: 1 }}>
          <VideoSub
            src='/assets/video/Film 24.mp4'
            sub={t('tinantia.movie.1')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Tinantia;
