import React from 'react';
import VideoSub from '../components/VideoSub';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Figure from 'react-bootstrap/Figure';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Ratio from 'react-bootstrap/esm/Ratio';

const Home = () => {
  const { t } = useTranslation('home');
  return (
    <Container fluid>
      <Row>
        <Col xs={12} lg={6}>
          <Row>
            <Col>
              <p>
                <b>{t('text.0')}</b> {t('text.1')} <i>{t('text.2')}</i>{' '}
                {t('text.3')} <i>{t('text.4')}</i> {t('text.5')}
              </p>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col>
              <Image fluid alt='home1' src='/assets/img/home1.jpg' />
            </Col>
            <Col>
              <Image fluid alt='home2' src='/assets/img/home2.png' />
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={6}>
          <VideoSub src='/assets/video/Film 1.mp4' sub={t('video.0')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            {t('text.6')}{' '}
            <a href='http://darwin-online.org.uk/EditorialIntroductions/Freeman_ThePowerofMovementinPlants.html'>
              The Power of Movement in Plants
            </a>
            {'. '}
            {t('text.7')}{' '}
            <Link to='/plants/arabidopsis'>Arabidopsis thaliana</Link>
            {', '}
            <Link to='/plants/lupinus'>Lupinus angustifolius</Link>
            {', '}
            <Link to='/plants/tinantia'>Tinantia anomala</Link>
            {', '}
            <Link to='/plants/medicago'>Medicago truncatula</Link>
            {'.'}
          </p>
          <p>
            {t('text.8')} <Link to='/sunflower/ultradian'>{t('text.9')}</Link>{' '}
            {t('text.10')}
          </p>
          <p>
            {t('text.11')} <Link to='/sunflower/daily'>{t('text.12')}</Link>{' '}
            {t('text.13')}
          </p>
          <p>
            {t('text.14')} <Link to='/sunflower/light'>{t('text.15')}</Link>{' '}
            {t('text.16')} <Link to='/sunflower/thermal'>{t('text.17')}</Link>
          </p>
          <p>
            <Link to='/sunflower/movement'>{t('text.18')}</Link> {t('text.19')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <p>
            {t('text.20')} <Link to='/sunflower/growth'> {t('text.21')}</Link>{' '}
            {t('text.22')}{' '}
            <Link to='/sunflower/electrical'>{t('text.23')}</Link>
          </p>
          <p>
            {t('text.24')}
            <Link to='/ct'> {t('text.25')} </Link>
          </p>
          <p>
            {t('text.26')}{' '}
            <Link to='/plants/tinantia'>
              <i>Tinantia Anomala</i>
            </Link>{' '}
            {t('text.27')}{' '}
            <Link to='/plants/ipomea'>
              <i>Ipomea purpurea</i>
            </Link>
            {'.'}
          </p>
          <p>
            {t('text.28')}{' '}
            <Link to='/plants/desmodium'>
              {' '}
              <i>Desmodium motorium</i>,
            </Link>{' '}
            <Link to='/plants/mimosa'>
              {' '}
              <i>Mimosa pudica</i>,
            </Link>{' '}
            <Link to='/plants/phaseolus'>
              {' '}
              <i>Phaseolus</i>
            </Link>{' '}
            {t('text.29')}
          </p>
        </Col>
        <Col xs={12} lg={6}>
          <Figure>
            <Container>
              <Row className='align-items-center'>
                <Col>
                  <Figure.Image alt='home3' src='/assets/img/home3.jpg' />
                </Col>
                <Col>
                  <Figure.Image alt='home4' src='/assets/img/home4.jpg' />
                </Col>
              </Row>
            </Container>
            <Figure.Caption>{t('figure')}</Figure.Caption>
          </Figure>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub src={'/assets/video/Film 2.mp4'} sub={t('video.1')} />
        </Col>
        <Col xs={12} md={6}>
          <VideoSub src={'/assets/video/Film 3.mp4'} sub={t('video.2')} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub src={'/assets/video/Film 4.mp4'} sub={t('video.3')} />
        </Col>
        <Col xs={12} md={6}>
          <Ratio aspectRatio="16x9">
            <iframe
              height='100%'
              width='100%'
              src='https://www.youtube-nocookie.com/embed/12JFjTXTt7Q?si=5NQN3ld2DE7cld38'
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            ></iframe>
          </Ratio>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
