import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';

const Phaseolus = () => {
  const { t } = useTranslation('plants');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>{t('phaseolus.title')}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub
            src='/assets/video/Film 12.mp4'
            sub={t('phaseolus.movie.0')}
          />
        </Col>
        <Col xs={12} md={6}>
          <VideoSub
            src='/assets/video/Film 13.mp4'
            sub={t('phaseolus.movie.1')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub
            src='/assets/video/Film 14.mp4'
            sub={t('phaseolus.movie.2')}
          />
        </Col>
        <Col xs={12} md={6}>
          <VideoSub
            src='/assets/video/Film 15.mp4'
            sub={t('phaseolus.movie.3')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub
            src='/assets/video/Film 16.mp4'
            sub={t('phaseolus.movie.4')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Phaseolus;
