import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Image from 'react-bootstrap/esm/Image';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('footer');
  return (
    <footer className={'bg-primary mt-2'}>
      <Container fluid className={'d-flex flex-column align-items-center pt-3'}>
        <Col xs={12} sm={9} md={6} lg={3}>
          <a href={t('facultyLink')}>
            <Image fluid src='/assets/img/UMCS_biale.svg'></Image>
          </a>
        </Col>
        <p className={'text-light text-center'}>
          <a href={t('authorLink')} className={'text-light'}>Maria Stolarz</a>
          <br />
          {t('contact')}
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
