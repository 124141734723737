import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';
import IPublication from '../../models/IPublication';
import PublicationItem from '../../components/PublicationItem';

const publications: IPublication[] = [
  {
    authors: 'Stolarz M, Król E, Dziubińska H, Zawadzki T',
    year: 2008,
    title:
      'Complex relationship between growth and circumnutations in Helianthus annuus stem. Plant Signaling & Behavior  3: 376-380',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2634306/',
  },
  {
    authors: 'Stolarz, M, Król E, Dziubińska H',
    year: 2015,
    title:
      'Lithium distinguishes between growth and circumnutation and augments glutamate-induced excitation of Helianthus annuus seedlings. Acta Physiologiae Plantarum  37 (4):69',
    link: 'https://link.springer.com/article/10.1007/s11738-015-1814-y',
  },
  {
    authors: 'Stolarz M, Dziubińska H',
    year: 2017,
    title:
      'Osmotic and salt stresses modulate spontaneous and glutamate-induced action potentials and distinguish between growth and circumnutation in Helianthus annuus seedlings. Frontiers in Plant Science 8:1766',
    link: 'https://www.frontiersin.org/articles/10.3389/fpls.2017.01766/full',
  },
];

const Growth = () => {
  const { t } = useTranslation('sunflower');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>{t('growth.title')}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub src='/assets/video/Film 5.mp4' sub={t('growth.movie')} />
        </Col>
        <Col xs={12} md={6}>
          <p>{t('growth.text')}{' ('}<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2634306/">{t('growth.link')}</a>{').'}</p>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          {publications.map((pub, index) => (
            <PublicationItem key={index} publication={pub} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Growth;
