import React from 'react';
import { useTranslation } from 'react-i18next';
import PublicationItem from '../components/PublicationItem';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import IPublication from '../models/IPublication';

const publications: IPublication[] = [
  {
    authors: 'Emilia Łabuć, Jarosław Bylina, Maria Stolarz, Przemysław Stpiczyński, Małgorzata Pac-Sosińska, Kazimierz Trębacz, Anna Jarosz-Wilkołazka',
    year: 2023,
    title:
      'Nowe algorytmy informatyczne w analizie sygnałów elektrycznych u Lupinus angustifolius L. Warsztaty Biofizyczne, Kazimierz Dolny, 26 maja 2023 r. str. 16.',
    link: '',
  },
];

const Sap = () => {
  const { t } = useTranslation('sap');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>SAP Tracker</h1>
          <Row>
            <p>
              <a href='https://www.umcs.pl/pl/addres-book-employee,17983,pl.html'>
                <b>{t('author')}</b>
              </a>
            </p>
          </Row>
          <Row>
            <p>
              <b>{t('text.0')}</b> {t('text.1')}
            </p>
          </Row>
          <Row>
            <p>
              {t('text.2')}{' '}
              <ul>
                <li>{t('list.0')}</li>
                <li>{t('list.1')}</li>
                <li>{t('list.2')}</li>
                <li>{t('list.3')}</li>
              </ul>
            </p>
          </Row>
          <Row>
            <Col className='d-grid'>
              <Button
                size={'lg'}
                variant='primary'
                className={'mb-2'}
                href={'assets/downloads/SAP_Tracker.zip'}
              >
                {t('link.0')}
              </Button>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col className='d-grid'>
              <Button
                variant='outline-primary'
                href={'assets/downloads/SAP_Tracker_User_Guide.pdf'}
              >
                {t('link.1')}
              </Button>
            </Col>
            <Col className='d-grid'>
              <Button
                variant='outline-primary'
                href={
                  'assets/downloads/SAP_Tracker_Terms_of_Use.pdf'
                }
              >
                {t('link.2')}
              </Button>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Image fluid alt='sap3' src='/assets/img/sap3.png' />
          </Row>
          <Row className='mb-3'>
            <Col xs={12} md={4}>
              <Image fluid alt='sap1' src='/assets/img/sap1.png' />
            </Col>
            <Col xs={12} md={4}>
              <Image fluid alt='sap2' src='/assets/img/sap2.png' />
            </Col>
            <Col xs={12} md={4}>
              <Image fluid alt='sap4' src='/assets/img/sap4.png' />
            </Col>
          </Row>
          {publications.map((pub, index) => (
            <PublicationItem key={index} publication={pub} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};
export default Sap;
