import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';
import Image from 'react-bootstrap/esm/Image';

const Movements = () => {
  const { t } = useTranslation('sunflower');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>{t('movements.title')}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub src='/assets/video/Film 32.mp4' sub='' />
        </Col>
        <Col xs={12} md={6}>
          <p><b>{t('movements.movie.0')}</b> {t('movements.movie.1')}<span style={{color:'blue'}}>{'*'}</span>{", "}{t('movements.movie.2')}<span style={{color:'red'}}>{'*'}</span>{", "}{t('movements.movie.3')}</p>
          <Image fluid src='/assets/img/movements.jpg' />
        </Col>
      </Row>
    </Container>
  );
};

export default Movements;
