import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';
import PublicationItem from '../../components/PublicationItem';
import IPublication from '../../models/IPublication';
import { Link } from 'react-router-dom';

const publications: IPublication[] = [
  {
    authors: 'Stolarz M, Dziubińska H',
    year: 2017,
    title:
      'Osmotic and salt stresses modulate spontaneous and glutamate-induced action potentials and distinguish between growth and circumnutation in Helianthus annuus seedlings. Frontiers in Plant Science 8:1766',
    link: 'https://www.frontiersin.org/articles/10.3389/fpls.2017.01766/full',
  },
  {
    authors: 'Stolarz M, Dziubińska H',
    year: 2017,
    title:
      'Spontaneous action potentials and circumnutation in Helianthus annuus. Acta Physiologiae Plantarum 39:234',
    link: 'https://link.springer.com/article/10.1007/s11738-017-2528-0',
  },
  {
    authors: 'Zawadzki T, Dziubińska H, Davies E',
    year: 1995,
    title:
      'Characteristic of action potentials generated spontaneously in Helianthus annuus. Physiologia Plantarum 93: 291-297',
    link:
      'https://onlinelibrary.wiley.com/doi/full/10.1111/j.1399-3054.1995.tb02231.x',
  },
];

const Electrical = () => {
  const { t } = useTranslation('sunflower');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>{t('electrical.title')}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            {t('electrical.text.0')}{' '}
            <Link to='/sunflower/thermal'>{t('electrical.text.1')}</Link>{' '}
            {t('electrical.text.2')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{t('electrical.h2.0')}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            {t('electrical.text.3')} <b>{t('electrical.text.4')}</b>{' '}
            {t('electrical.text.5')}{' '}
            <b>{t('electrical.text.6')}</b> {t('electrical.text.7')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <VideoSub
            src='/assets/video/Film 6.mp4'
            sub={t('electrical.movie')}
          />
        </Col>
        <Col>
          <p>{t('electrical.text.8')} <a href="https://www.frontiersin.org/articles/10.3389/fpls.2017.01766/full#supplementary-material">{t('electrical.text.9')}</a></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{t('electrical.h2.1')}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{t('electrical.text.10')} <b>{t('electrical.text.11')}</b> {t('electrical.text.12')} <a href="https://link.springer.com/article/10.1007/s11738-017-2528-0#SupplementaryMaterial">{t('electrical.text.13')}</a></p>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          {publications.map((pub, index) => (
            <PublicationItem key={index} publication={pub} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Electrical;
