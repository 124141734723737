import React from 'react';
import '../styles/App.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import NavItem from 'react-bootstrap/esm/NavItem';
import NavLink from 'react-bootstrap/esm/NavLink';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { MdLanguage } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/esm/Container';

const Navigation = () => {
  const { t, i18n } = useTranslation('navigation');
  return (
    <Navbar expand='lg' bg='primary' variant='dark' className='mb-3'>
      <Container fluid className='wrapper'>
        <Navbar.Brand as={RouterNavLink} to='/'>
          Circumnutation
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='navbarScroll' />
        <Navbar.Collapse id='navbarScroll'>
          <Nav>
            <NavDropdown
              title={t('sunflower')}
              id='sunflower-dropdown'
              className={'navbar-link-main'}
            >
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/sunflower/growth'
              >
                {t('sunItems.0')}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/sunflower/electrical'
              >
                {t('sunItems.1')}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/sunflower/ultradian'
              >
                {t('sunItems.2')}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/sunflower/daily'
              >
                {t('sunItems.3')}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/sunflower/light'
              >
                {t('sunItems.4')}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/sunflower/thermal'
              >
                {t('sunItems.5')}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/sunflower/movements'
              >
                {t('sunItems.6')}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={t('plants')}
              id='plants-dropdown'
              className={'navbar-link-main'}
            >
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/plants/mimosa'
              >
                Mimosa pudica
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/plants/desmodium'
              >
                Desmodium motorium
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/plants/phaseolus'
              >
                Phaseolus coccineus
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/plants/medicago'
              >
                Medicago truncatula
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/plants/lupinus'
              >
                Lupinus angustifolius
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/plants/arabidopsis'
              >
                Arabidopsis thaliana
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/plants/tinantia'
              >
                Tinantia anomala
              </NavDropdown.Item>
              <NavDropdown.Item
                as={RouterNavLink}
                className={'navbar-link-sub'}
                to='/plants/ipomea'
              >
                Ipomea purpurea
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={RouterNavLink}
              to='/ct'
              className={'navbar-link-main'}
            >
              Circumnutation Tracker
            </Nav.Link>
            <Nav.Link
              as={RouterNavLink}
              to='/sap-tracker'
              className={'navbar-link-main'}
            >
              SAP Tracker
            </Nav.Link>
            <Nav.Link
              as={RouterNavLink}
              to='/literature'
              className={'navbar-link-main'}
            >
              {t('literature')}
            </Nav.Link>
            <Dropdown as={NavItem} className={'navbar-link-main'}>
              <Dropdown.Toggle as={NavLink}>
                <MdLanguage className='mb-1'/>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={NavLink}
                  className={'navbar-link-sub text-black'}
                  onClick={() => i18n.changeLanguage('pl')}
                >
                  Polski
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  className={'navbar-link-sub text-black'}
                  onClick={() => i18n.changeLanguage('en')}
                >
                  English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
