import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';
import Figure from 'react-bootstrap/Figure';

const Mimosa = () => {
  const { t } = useTranslation('plants');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Mimosa pudica</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p><i>Mimosa pudica</i> {t('mimosa.text')}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Figure>
            <Figure.Image alt='mimosa' src='/assets/img/mimosa.jpg' />
            <Figure.Caption>{t('mimosa.figure')}</Figure.Caption>
          </Figure>
        </Col>
        <Col xs={12} md={6}>
          <VideoSub src='/assets/video/Film 7.mp4' sub={t('mimosa.movie')} />
        </Col>
      </Row>
    </Container>
  );
};

export default Mimosa;
