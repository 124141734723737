import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import VideoSub from '../components/VideoSub';
import IPublication from '../models/IPublication';
import PublicationItem from '../components/PublicationItem';

const publications: IPublication[] = [
  {
    authors: 'Stolarz Maria, Żuk Maciej, Elżbieta Król, Dziubińska Halina',
    year: 2014,
    title:
      'Circumnutation Tracker: novel software for investigation of circumnutation. Plant Methods 2014 10:24 doi:10.1186/1746-4811-10-24',
    link: 'http://www.plantmethods.com/content/10/1/24',
  },
];

const Ct = () => {
  const { t } = useTranslation('ct');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Circumnutation Tracker</h1>
          <h2>{t('text.0')}</h2>
          <p>{t('text.1')}</p>
          <Row>
            <Col className='d-grid'>
              <Button
                size={'lg'}
                variant='primary'
                className={'mb-2'}
                href={'assets/downloads/Circumnutation Tracker.zip'}
              >
                {t('link.0')}
              </Button>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col className='d-grid'>
              <Button
                variant='outline-primary'
                href={'assets/downloads/CT guide.pdf'}
              >
                {t('link.1')}
              </Button>
            </Col>
            <Col className='d-grid'>
              <Button
                variant='outline-primary'
                href={
                  'assets/downloads/Circumnutation Tracker Terms of Use.pdf'
                }
              >
                {t('link.2')}
              </Button>
            </Col>
          </Row>
          <VideoSub
            src='/assets/video/Film Plant Methods.mp4'
            sub={t('movie')}
          />
          {publications.map((pub, index) => (
            <PublicationItem key={index} publication={pub} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Ct;
