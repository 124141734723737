import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => {
  return (
    <Container
      fluid
      className='h-100 d-flex justify-content-center align-items-center'
    >
      <Spinner animation='border' variant='primary'/>
    </Container>
  );
};

export default Loading;
