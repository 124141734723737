import React from 'react'
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';

const Arabidopsis = () => {
    const { t } = useTranslation('plants');
    return (
      <Container fluid>
        <Row>
          <Col>
          <h1>Arabidopsis thaliana</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <VideoSub
              src='/assets/video/Film 21.mp4'
              sub={t('arabidopsis.movie.0')}
            />
          </Col>
          <Col xs={12} md={6}>
            <VideoSub
              src='/assets/video/Film 22.mp4'
              sub={t('arabidopsis.movie.1')}
            />
          </Col>
        </Row>
      </Container>
    );
}

export default Arabidopsis
