import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';
import Image from 'react-bootstrap/esm/Image';
import PublicationItem from '../../components/PublicationItem';
import IPublication from '../../models/IPublication';

const publications: IPublication[] = [
  {
    authors: 'Stolarz M, Król E, Dziubińska H, Zawadzki T',
    year: 2008,
    title:
      'Complex relationship between growth and circumnutations in Helianthus annuus stem. Plant Signaling & Behavior  3: 376-380',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2634306/',
  },
];

const Light = () => {
  const { t } = useTranslation('sunflower');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>{t('light.title')}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <VideoSub src='/assets/video/Film 29.webm' sub={t('light.movie')} />
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <p>{t('light.text.0')}</p>
            </Col>
            <Col>
              <p>{t('light.text.1')}</p>
            </Col>
          </Row>
          <Row>
            <Image fluid src='/assets/img/light.jpg' />
          </Row>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          {publications.map((pub, index) => (
            <PublicationItem key={index} publication={pub} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Light;
