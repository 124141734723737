import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import VideoSub from '../../components/VideoSub';
import Image from 'react-bootstrap/esm/Image';
import PublicationItem from '../../components/PublicationItem';
import IPublication from '../../models/IPublication';

const publications: IPublication[] = [
  {
    authors: 'Stolarz M, Dziubińska H, Krupa M, Buda A, Trębacz K, Zawadzki T',
    year: 2003,
    title:
      'Disturbances of stem circumnutations evoked by wound-induced variation potentials in Helianthus annuus L. Cellular & Molecular Biology Letters 8: 31-40',
    link: 'http://www.cmbl.org.pl/pdf/Vol8_p031.pdf',
  },
];

const Thermal = () => {
  const { t } = useTranslation('sunflower');
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>{t('thermal.title')}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <VideoSub
            src='/assets/video/Film 30.webm'
            sub={t('thermal.movie.0')}
          />
        </Col>
        <Col xs={12} lg={6}>
          <Row>
            <Col xs='auto'>
              <Image fluid src='/assets/img/thermal1.jpg' />
            </Col>
            <Col>
              <p>{t('thermal.text.0')}</p>
              <Image fluid src='/assets/img/thermal2.jpg' />
              <p>{t('thermal.text.1')}</p>
              <p>{t('thermal.text.2')}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <VideoSub
                src='/assets/video/Film 31.webm'
                sub={t('thermal.movie.1')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          {publications.map((pub, index) => (
            <PublicationItem key={index} publication={pub} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Thermal;
